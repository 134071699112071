import { PageMeta } from '@graphcommerce/magento-store'
import { Container } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

const NotFound = () => {
  const router = useRouter()
  const backwardNavigation = () => (window.history.length > 1 ? router.back() : router.push('/'))

  return (
    <>
      <div className='md:mt-52 mainLayout'>
        <PageMeta title='Page not found' metaRobots={['noindex']} />
        <Container
          maxWidth='sm'
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image src={'/404.webp'} width={400} height={180} alt='404' />
          <div className='flex flex-col'>
            <h2 className='text-2xl my-6 text-ordme-gray-400'>صفحه مورد نظر یافت نشد!</h2>
            <div className='pb-6 flex items-center justify-center gap-3'>
              <button
                onClick={backwardNavigation}
                className='text-white px-2 py-1 rounded-sm bg-ordme-gray-400 hover:bg-ordme-purple transition-all duration-100'
              >
                بازگشت به صفحه قبل
              </button>
              <button>
                <Link
                  href='/'
                  className='text-white px-2 py-1 rounded-sm bg-ordme-purple hover:opacity-80 transition-all duration-100'
                >
                  صفحه اصلی
                </Link>
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default NotFound
