import { PageOptions } from '@graphcommerce/framer-next-pages'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps } from '@graphcommerce/next-ui'
import { DevLayoutNavigation, LayoutNavigationProps } from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { DefaultPageDocument, DefaultPageQuery } from '../graphql/DefaultPage.gql'
import { MegaMenuContentDocument } from '../graphql/MegaMenuContent.gql'
import { storeLogoDocument } from '../graphql/storeLogo.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'
import NotFound from '../components/404'

type Props = DefaultPageQuery
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props>

function RouteNotFoundPage() {
  return <NotFound/>
}

RouteNotFoundPage.pageOptions = {
  Layout: DevLayoutNavigation,
} as PageOptions

export default RouteNotFoundPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)
  const conf = client.query({ query: StoreConfigDocument })

  const page = staticClient.query({ query: DefaultPageDocument, variables: { url: `/` } })
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })
  const menuContent = staticClient.query({ query: MegaMenuContentDocument })
  const storeLogo = staticClient.query({ query: storeLogoDocument })
  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      ...(await menuContent)?.data,
      ...(await storeLogo)?.data,

      up: { href: '/', title: 'Home' },
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 5,
  }
}
